<template>
  <b-alert v-model="newGame" class="m-3">
    <span class="mr-2">
      Er staat een wedstrijd voor je klaar om te bevestigen!
    </span>
    <b-button variant="primary" to="/wedstrijden">
      Bekijk nieuwe wedstrijd
    </b-button>
  </b-alert>
</template>

<script>
import { mapState } from 'vuex'

export default {
  computed: {
    ...mapState(['userProfile', 'games']),
    newGame() {
      // check if there's a new game for you
      let gameToAccept = this.games.filter(game => {
        if (game.pending && game.contester.id === this.userProfile.id) {
          return game
        }
      })

      return gameToAccept.length > 0
    }
  }
}
</script>
