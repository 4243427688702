import * as firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/firestore'

// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: 'AIzaSyCWOQGn9cO9CoMpwawzHO9o5qjlruuDqYE',
  authDomain: 'laddercompetitie-57af8.firebaseapp.com',
  databaseURL: 'https://laddercompetitie-57af8.firebaseio.com',
  projectId: 'laddercompetitie-57af8',
  storageBucket: 'laddercompetitie-57af8.appspot.com',
  messagingSenderId: '796624148888',
  appId: '1:796624148888:web:efd046bb0f116377b173ed',
  measurementId: 'G-50ZS2XRSWB'
}
firebase.initializeApp(firebaseConfig)

// utils
const db = firebase.firestore()
const auth = firebase.auth()

// collection references
const usersCollection = db.collection('users')
const gamesCollection = db.collection('games')

// export utils/refs
export { db, auth, usersCollection, gamesCollection }
