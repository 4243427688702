<template>
  <div id="app">
    <Loader v-if="loader" />
    <template v-else>
      <UserNotApproved v-if="userActive && !this.userProfile.approved" />
      <template v-else>
        <NewGameAlert />
        <NewUserAlert />
        <transition name="fade" mode="out-in">
          <router-view />
        </transition>
      </template>
      <SiteNav v-if="userActive && this.userProfile.approved"></SiteNav>
    </template>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import SiteNav from '@/components/SiteNav'
import Loader from '@/components/Loader'
import UserNotApproved from '@/components/UserNotApproved'
import NewGameAlert from '@/components/NewGameAlert'
import NewUserAlert from '@/components/NewUserAlert'

export default {
  components: {
    SiteNav,
    Loader,
    UserNotApproved,
    NewGameAlert,
    NewUserAlert
  },
  computed: {
    ...mapState(['userProfile', 'loader']),
    userActive() {
      return Object.keys(this.userProfile).length > 1
    }
  }
}
</script>

<style lang="scss">
@import './assets/styles/custom.scss';
@import '../node_modules/bootstrap/scss/bootstrap.scss';
</style>
