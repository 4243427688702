<template>
  <b-navbar
    toggleable="lg"
    fixed="bottom"
    type="dark"
    variant="primary"
    class="p-0 border-top border-secondary"
  >
    <b-container class="d-flex justify-content-around">
      <router-link
        v-for="(item, index) in items"
        :key="index"
        class="nav-item d-flex flex-column text-center flex-even"
        :to="item.path"
        :active="isActive"
        exact
      >
        <b-icon
          :icon="item.icon"
          class="align-self-center my-2"
          variant="light"
          font-scale="1.7"
        ></b-icon>
        <small class="text-light mb-1">{{ item.name }}</small>
      </router-link>
    </b-container>
  </b-navbar>
</template>
<script>
export default {
  created() {
    this.$router.options.routes.forEach(route => {
      if (!route.excludeMenu) {
        this.items.push({
          name: route.name,
          path: route.path,
          icon: route.icon
        })
      }
    })
  },
  data() {
    return {
      items: [],
      isActive: false
    }
  }
}
</script>
