var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-container',[_c('AddGameModal',{attrs:{"contester":_vm.selectedUser,"title":"Vul wedstrijd gegevens in"}}),_c('CompetionNotStarted'),_c('h2',{staticClass:"d-flex align-items-center mb-2 py-2"},[_c('b-icon',{staticClass:"mr-2",attrs:{"icon":"trophy"}}),_vm._v("Ladder ranglijst ")],1),(_vm.approvedUsers.length)?_c('div',_vm._l((_vm.approvedUsers),function(user,index){return _c('b-card',{key:index,staticClass:"mb-2",attrs:{"bg-variant":"dark","header-class":'ranking-' + _vm.ranking(user.ladderRating),"header-bg-variant":"secondary"},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('div',{staticClass:"d-flex justify-content-between align-items-center"},[_c('div',[(!_vm.competitionOnHold)?_c('b',{staticClass:"badge badge-transparent mr-1"},[_vm._v("#"+_vm._s(user.ladderRating))]):_vm._e(),_c('b',{staticClass:"ml-1"},[_vm._v(_vm._s(user.name))])]),_c('div',[(index === 0 && !_vm.competitionOnHold)?_c('b-icon',{staticClass:"ml-2",attrs:{"icon":"trophy-fill"}}):_vm._e(),(user.id === _vm.userProfile.id)?_c('b-icon',{staticClass:"ml-2",attrs:{"icon":"person-fill"}}):_vm._e()],1)])]},proxy:true},{key:"footer",fn:function(){return [_c('div',{staticClass:"d-flex justify-content-between align-items-center"},[_c('small',{staticClass:"text-muted"},[_vm._v(" Totale punten: "+_vm._s(user.totalPoints)+" ")]),(
              _vm.ladderLogic(user) &&
                !_vm.gamePendingUser(user) &&
                !_vm.competitionOnHold
            )?_c('b-button',{directives:[{name:"b-modal",rawName:"v-b-modal.addgamemodal",modifiers:{"addgamemodal":true}}],attrs:{"variant":"primary"},on:{"click":function($event){return _vm.setSelectedUser(user)}}},[_vm._v(" Daag uit ")]):_vm._e()],1)]},proxy:true}],null,true)},[(
          user.id === _vm.userProfile.id &&
            _vm.userProfile.ladderRating === 1 &&
            !_vm.competitionOnHold
        )?_c('b-card-text',{staticClass:"text-muted"},[_c('b-icon',{staticClass:"mr-2",attrs:{"icon":"trophy-fill","variant":"warning"}}),_vm._v(" Gefeliciteerd, nummer 1! ")],1):_vm._e(),(_vm.lastGameDate(user))?_c('b-card-text',[_c('small',[_c('b',[_vm._v("Laaste wedstrijd:")]),_vm._v(" "+_vm._s(_vm._f("formatDate")(_vm.lastGameDate(user)))+" ")])]):_c('b-card-text',[_c('small',{staticClass:"text-muted"},[_vm._v("Deze speler heeft nog niet gespeeld")])]),(_vm.ladderLogic(user) && _vm.gamePendingUser(user))?[(_vm.gamePendingUserProfileToAccept())?_c('b-card-text',[_c('small',[_vm._v(" Accepteer eerst je eigen wedstrijd voordat je andere kan uitdagen ")])]):_vm._e(),(_vm.gamePendingUserToAccept(user))?_c('b-card-text',[_c('small',[_vm._v(" Deze persoon heeft een wedstrijd gespeeld die nog geaccepteerd moet worden ")])]):_vm._e(),(_vm.gamePendingRatingSameAsOpponent(user))?_c('b-card-text',[_c('small',[_vm._v(" Je hebt een wedstrijd gespeeld tegen iemand met dezelfde rating als deze speler. Wacht tot je wedstrijd is geaccepteerd door de tegenstander ")])]):_vm._e()]:_vm._e()],2)}),1):_vm._e(),(!_vm.approvedUsers.length && !_vm.loader)?_c('div',[_c('p',{staticClass:"no-results"},[_vm._v("Er zijn op het moment nog geen gebruikers")])]):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }