import Vue from 'vue'
import Vuex from 'vuex'
import * as fb from '../firebase'
import { auth } from '../firebase'
import router from '../router/index'

Vue.use(Vuex)

const store = new Vuex.Store({
  state: {
    adm1n: 'sCwBeVqYhvhVweOksNp8FXpJYzu2',
    competitionOnHold: false,
    userProfile: {},
    users: [],
    usersSortByPoints: [],
    games: [],
    newGame: false,
    loader: true
  },
  mutations: {
    setUserProfile(state, val) {
      state.userProfile = val
    },
    setUsers(state, val) {
      state.users = val
    },
    setUsersSortByPoints(state, val) {
      state.usersSortByPoints = val
    },
    setLoader(state, val) {
      state.loader = val
    },
    setGames(state, val) {
      state.games = val
    },
    setNewGame(state, val) {
      state.newGame = val
    }
  },
  actions: {
    async fetchUserProfile({ commit }, user) {
      // fetch user profile
      const userProfile = await fb.usersCollection.doc(user.uid).get()

      // set user profile in state
      commit('setUserProfile', userProfile.data())

      // change route to LadderCompetitie
      if (
        router.currentRoute.path === '/login' ||
        router.currentRoute.path === '/aanmelden'
      ) {
        router.push('/')
      }

      // Set loader to false
      store.commit('setLoader', false)
    },

    async logout({ commit }) {
      await fb.auth.signOut()

      // clear userProfile and redirect to /login
      commit('setUserProfile', {})
      router.push('/login')
    }
  },
  modules: {}
})

// realtime firebase connection
fb.usersCollection.orderBy('ladderRating', 'asc').onSnapshot(snapshot => {
  // Set loader to false
  store.commit('setLoader', true)

  let usersArray = []

  // Update the user profile
  auth.onAuthStateChanged(user => {
    if (user) store.dispatch('fetchUserProfile', user)
  })

  snapshot.forEach(doc => {
    let user = doc.data()

    user.id = doc.id
    usersArray.push(user)
  })

  // Set users for using in vue
  store.commit('setUsers', usersArray)
})

// realtime firebase connection
fb.usersCollection.orderBy('totalPoints', 'desc').onSnapshot(snapshot => {
  // Set loader to false
  store.commit('setLoader', true)

  let usersArray = []

  snapshot.forEach(doc => {
    let user = doc.data()

    user.id = doc.id
    if (user.approved) usersArray.push(user)
  })

  // Set users for using in vue
  store.commit('setUsersSortByPoints', usersArray)
})

// realtime firebase connection
fb.gamesCollection.orderBy('createdOn', 'desc').onSnapshot(snapshot => {
  // Set loader to false
  store.commit('setLoader', true)

  let gamesArray = []

  // Update the user profile
  auth.onAuthStateChanged(user => {
    if (user) store.dispatch('fetchUserProfile', user)
  })

  snapshot.forEach(doc => {
    let game = doc.data()

    game.id = doc.id
    gamesArray.push(game)
  })
  // Set loader to false
  store.commit('setLoader', false)
  // Set games for using in vue
  store.commit('setGames', gamesArray)
})

export default store
