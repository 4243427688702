<template>
  <b-container class="mt-3">
    <div class="bg-dark p-3 py-4">
      <b-img
        :src="require(`@/assets/img/logo-ttv-harderwijk-ladder.svg`)"
        alt="Logo TTV Harderwijk"
        width="150"
        rounded
        center
        class="mb-4 shadow"
      />
      <h1>Welkom</h1>
      <p>Dit is de Ladder Competitie webapp van TTV Harderwijk.</p>
      <p>
        De administrator (Chris van der Leij) moet je account eerst goed keuren
        voordat je mee kunt doen. Stuur een mail naar
        <a href="mailto:info@ttvharderwijk.nl">info@ttvharderwijk.nl</a> wanneer
        je dit te lang vind duren.
      </p>

      <b-button variant="primary" @click="logout">Uitloggen</b-button>
    </div>
  </b-container>
</template>

<script>
export default {
  data() {
    return {}
  },
  methods: {
    logout() {
      this.$store.dispatch('logout')
    }
  }
}
</script>
