<template>
  <b-container>
    <AddGameModal :contester="selectedUser" title="Vul wedstrijd gegevens in" />
    <CompetionNotStarted />

    <h2 class="d-flex align-items-center mb-2 py-2">
      <b-icon icon="trophy" class="mr-2"></b-icon>Ladder ranglijst
    </h2>

    <div v-if="approvedUsers.length">
      <b-card
        v-for="(user, index) in approvedUsers"
        :key="index"
        bg-variant="dark"
        :header-class="'ranking-' + ranking(user.ladderRating)"
        header-bg-variant="secondary"
        class="mb-2"
      >
        <template #header>
          <div class="d-flex justify-content-between align-items-center">
            <div>
              <b class="badge badge-transparent mr-1" v-if="!competitionOnHold"
                >#{{ user.ladderRating }}</b
              >
              <b class="ml-1">{{ user.name }}</b>
            </div>
            <div>
              <b-icon
                v-if="index === 0 && !competitionOnHold"
                icon="trophy-fill"
                class="ml-2"
              >
              </b-icon>
              <b-icon
                v-if="user.id === userProfile.id"
                icon="person-fill"
                class="ml-2"
              >
              </b-icon>
            </div>
          </div>
        </template>
        <b-card-text
          class="text-muted"
          v-if="
            user.id === userProfile.id &&
              userProfile.ladderRating === 1 &&
              !competitionOnHold
          "
        >
          <b-icon icon="trophy-fill" class="mr-2" variant="warning"> </b-icon>
          Gefeliciteerd, nummer 1!
        </b-card-text>

        <b-card-text v-if="lastGameDate(user)">
          <small>
            <b>Laaste wedstrijd:</b> {{ lastGameDate(user) | formatDate }}
          </small>
        </b-card-text>
        <b-card-text v-else>
          <small class="text-muted">Deze speler heeft nog niet gespeeld</small>
        </b-card-text>

        <template v-if="ladderLogic(user) && gamePendingUser(user)">
          <b-card-text v-if="gamePendingUserProfileToAccept()">
            <small>
              Accepteer eerst je eigen wedstrijd voordat je andere kan uitdagen
            </small>
          </b-card-text>

          <b-card-text v-if="gamePendingUserToAccept(user)">
            <small>
              Deze persoon heeft een wedstrijd gespeeld die nog geaccepteerd
              moet worden
            </small>
          </b-card-text>

          <b-card-text v-if="gamePendingRatingSameAsOpponent(user)">
            <small>
              Je hebt een wedstrijd gespeeld tegen iemand met dezelfde rating
              als deze speler. Wacht tot je wedstrijd is geaccepteerd door de
              tegenstander
            </small>
          </b-card-text>
        </template>

        <template #footer class="justify-content-between">
          <div class="d-flex justify-content-between align-items-center">
            <small class="text-muted">
              Totale punten: {{ user.totalPoints }}
            </small>
            <b-button
              v-b-modal.addgamemodal
              variant="primary"
              v-if="
                ladderLogic(user) &&
                  !gamePendingUser(user) &&
                  !competitionOnHold
              "
              @click="setSelectedUser(user)"
            >
              Daag uit
            </b-button>
          </div>
        </template>
      </b-card>
    </div>

    <div v-if="!approvedUsers.length && !loader">
      <p class="no-results">Er zijn op het moment nog geen gebruikers</p>
    </div>
  </b-container>
</template>

<script>
import { mapState } from 'vuex'
import AddGameModal from '@/components/AddGameModal'
import CompetionNotStarted from '@/components/CompetionNotStarted'

import moment from 'moment'
import 'moment/locale/nl'
moment.locale('nl')

export default {
  data() {
    return {
      showGameModal: false,
      selectedUser: ''
    }
  },
  filters: {
    formatDate(val) {
      if (!val) {
        return '-'
      }

      let date = val.toDate()
      return moment(date).fromNow()
    }
  },
  computed: {
    ...mapState(['competitionOnHold', 'userProfile', 'users', 'games']),
    approvedUsers() {
      let approvedUsers = this.users.filter(user => {
        if (user.approved) {
          return user
        }
      })
      return approvedUsers
    }
  },
  methods: {
    setSelectedUser(user) {
      this.selectedUser = user
    },

    ranking(rating) {
      if (this.competitionOnHold) {
        return 'grey'
      } else {
        if (rating === 1) return 'gold'
        if (rating === 2) return 'silver'
        if (rating === 3) return 'bronze'
        if (rating >= 4) return 'grey'
      }
      return 'grey'
    },

    isProfile(user) {
      return this.userProfile.id === user.id
    },

    ladderLogic(user) {
      return (
        this.userProfile.ladderRating === user.ladderRating + 1 &&
        !this.isProfile(user)
      )
    },

    gamePendingUser(user) {
      let isPendingGameFromUser = this.games.filter(game => {
        if (
          (game.pending && game.contester.id === this.userProfile.id) ||
          (game.pending && game.challenger.id === this.userProfile.id) ||
          (game.pending && game.contester.id === user.id) ||
          (game.pending && game.challenger.id === user.id)
        ) {
          return game
        }
      })

      return isPendingGameFromUser.length > 0
    },

    gamePendingUserProfileToAccept() {
      let gameToAccept = this.games.filter(game => {
        if (game.pending && game.contester.id === this.userProfile.id) {
          return game
        }
      })

      if (gameToAccept.length > 0) this.notification = true

      return gameToAccept.length > 0
    },

    gamePendingUserToAccept(user) {
      let gameToAccept = this.games.filter(game => {
        if (
          (game.pending && game.contester.id === user.id) ||
          (game.pending && game.challenger.id === user.id)
        ) {
          return game
        }
      })

      return gameToAccept.length > 0
    },

    gamePendingRatingSameAsOpponent(user) {
      let gameRatingSameAsOpponent = this.games.filter(game => {
        if (
          game.pending &&
          game.challenger.id === this.userProfile.id &&
          game.contester.ladderRating === user.ladderRating &&
          game.contester.id !== user.id
        ) {
          return game
        }
      })

      return gameRatingSameAsOpponent.length > 0
    },
    lastGame(user) {
      let lastGameUser = this.games.filter(game => {
        if (user.lastGameId === game.id) {
          return game
        }
      })
      return lastGameUser
    },

    lastGameDate(user) {
      const lastGame = this.lastGame(user)

      if (lastGame.length) {
        return lastGame[0].createdOn
      }

      return false
    }
  },

  components: {
    AddGameModal,
    CompetionNotStarted
  }
}
</script>
