import Vue from 'vue'
import VueRouter from 'vue-router'
import LadderKlassement from '../views/LadderKlassement.vue'
import { auth } from '../firebase'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Ladder',
    icon: 'trophy',
    component: LadderKlassement,
    excludeMenu: false,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/punten-klassement',
    name: 'Punten',
    icon: 'file-binary',
    excludeMenu: false,
    component: () =>
      import(
        /* webpackChunkName: "settings" */ '../views/PuntenKlassement.vue'
      ),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/wedstrijden',
    name: 'Games',
    icon: 'people',
    excludeMenu: false,
    component: () =>
      import(/* webpackChunkName: "settings" */ '../views/Wedstrijden.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/spelregels',
    name: 'Regels',
    icon: 'justify',
    excludeMenu: false,
    component: () =>
      import(/* webpackChunkName: "settings" */ '../views/Spelregels.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/profiel',
    name: 'Profiel',
    icon: 'person',
    excludeMenu: false,
    component: () =>
      import(/* webpackChunkName: "settings" */ '../views/Profile.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/login',
    name: 'Login',
    excludeMenu: true,
    component: () =>
      import(/* webpackChunkName: "login" */ '../views/Login.vue')
  },
  {
    path: '/aanmelden',
    name: 'Aanmelden',
    excludeMenu: true,
    component: () =>
      import(/* webpackChunkName: "login" */ '../views/Aanmelden.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  const requiresAuth = to.matched.some(x => x.meta.requiresAuth)

  if (requiresAuth && !auth.currentUser) {
    next('/login')
  } else {
    next()
  }
})

export default router
