<template>
  <b-alert v-model="pendingUsers" variant="warning" class="m-3">
    <span class="mr-2">
      Er staat een gebruiker voor je klaar om te bevestigen!
    </span>
    <b-button variant="warning" to="/profiel">
      Bekijk nieuwe gebruiker
    </b-button>
  </b-alert>
</template>

<script>
import { mapState } from 'vuex'

export default {
  computed: {
    ...mapState(['userProfile', 'users', 'adm1n']),
    isAdmin() {
      return this.userProfile.id === this.adm1n
    },
    pendingUsers() {
      let pendingUsers = this.users.filter(user => {
        if (!user.approved) {
          return user
        }
      })
      return pendingUsers.length > 0 && this.isAdmin
    }
  }
}
</script>
