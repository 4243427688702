<template>
  <b-alert v-model="competitionOnHold" class="mt-3 mb-2">
    <span class="mr-2">
      De competitie is nog niet gestart. Hieronder vind je een lijst met
      gebruikers die zijn geaccepteerd door de administrator. De competitie
      start op <b>16 november 2020</b>.
    </span>
  </b-alert>
</template>

<script>
import { mapState } from 'vuex'

export default {
  computed: {
    ...mapState(['competitionOnHold'])
  }
}
</script>
