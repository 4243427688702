<template>
  <b-modal
    id="addgamemodal"
    :title="modalTitle"
    header-bg-variant="dark"
    header-border-variant="secondary"
    header-text-variant="light"
    body-bg-variant="dark"
    body-text-variant="light"
    footer-bg-variant="dark"
    footer-text-variant="light"
    footer-border-variant="secondary"
    header-close-variant="light"
  >
    <div v-if="challenged">
      <p>Speel een wedstrijd "best of 5" en onthoudt de stand</p>
    </div>

    <div v-if="!challenged && !checkByOtherPlayer">
      <p>Kies het aantal gewonnen games:</p>
      <form @submit.prevent>
        <div class="form-group row">
          <label class="col-5 col-form-label">
            {{ userProfile.name }}
          </label>
          <div class="col-7">
            <b-form-select
              v-model="selectedSetsChallenger"
              :options="options"
            ></b-form-select>
          </div>
        </div>
        <div class="form-group row">
          <label class="col-5 col-form-label">{{ contester.name }}</label>
          <div class="col-7">
            <b-form-select
              v-model="selectedSetsContester"
              :options="options"
            ></b-form-select>
          </div>
        </div>
      </form>
      <div class="bg-secondary p-2 rounded">
        <h5>Puntenverdeling:</h5>
        {{ userProfile.name }} : {{ selectedSetsChallenger + 1 }}<br />
        {{ contester.name }} : {{ selectedSetsContester + 1 }}
      </div>
      <div class="bg-primary text-white mt-2 p-2 rounded">
        <h5>Winnaar:</h5>
        {{ checkScore() }}
      </div>
    </div>

    <div v-if="checkByOtherPlayer">
      Zorg dat je tegenspeler je wedstrijd goedkeurd, pas dan worden de punten
      verdeeld en de rating gezet.
    </div>

    <template #modal-footer>
      <b-button
        v-if="checkByOtherPlayer"
        @click="checkByOtherPlayer = !checkByOtherPlayer"
        variant="secondary"
        class="button"
      >
        Terug
      </b-button>
      <b-button
        v-if="challenged"
        @click="challenged = !challenged"
        variant="primary"
        class="button"
      >
        Klaar? Vul de stand in
      </b-button>
      <b-button
        v-if="!challenged && !checkByOtherPlayer"
        @click="checkByOtherPlayer = !checkByOtherPlayer"
        variant="primary"
        :disabled="falseScore"
        class="button"
      >
        Ok
      </b-button>

      <b-button
        v-if="checkByOtherPlayer"
        @click="createGame()"
        variant="primary"
        :disabled="falseScore"
        class="button"
      >
        Wedstrijd insturen
      </b-button>
    </template>
  </b-modal>
</template>

<script>
import { mapState } from 'vuex'
import * as fb from '../firebase'

export default {
  props: ['title', 'contester'],
  data() {
    return {
      challenged: true,
      checkByOtherPlayer: false,
      selectedSetsChallenger: null,
      selectedSetsContester: null,
      falseScore: true,
      options: [
        { value: null, text: 'Aantal games gewonnen' },
        { value: 0, text: '0 ' },
        { value: 1, text: '1' },
        { value: 2, text: '2 ' },
        { value: 3, text: '3' }
      ]
    }
  },
  computed: {
    ...mapState(['userProfile']),
    modalTitle() {
      if (this.challenged) {
        return 'Speel eerst een wedstrijd'
      } else if (this.checkByOtherPlayer) {
        return 'Double check check!'
      } else {
        return 'Vul de stand in'
      }
    }
  },
  methods: {
    checkScore() {
      if (
        this.selectedSetsChallenger === 3 &&
        this.selectedSetsContester !== 3
      ) {
        this.falseScore = false
        return this.userProfile.name
      } else if (
        this.selectedSetsContester === 3 &&
        this.selectedSetsChallenger !== 3
      ) {
        this.falseScore = false
        return this.contester.name
      } else if (
        this.selectedSetsChallenger === null &&
        this.selectedSetsContester === null
      ) {
        this.falseScore = true
        return
      } else if (this.selectedSetsChallenger === this.selectedSetsContester) {
        this.falseScore = true
        return 'Gelijkspel is niet mogelijk'
      } else {
        this.falseScore = true
        return 'Vul de correcte score in'
      }
    },

    async createGame() {
      await fb.gamesCollection.add({
        createdOn: new Date(),
        challenger: {
          name: this.userProfile.name,
          id: this.userProfile.id,
          ladderRating: this.userProfile.ladderRating,
          setsWon: this.selectedSetsChallenger
        },
        contester: {
          name: this.contester.name,
          id: this.contester.id,
          ladderRating: this.contester.ladderRating,
          setsWon: this.selectedSetsContester
        },
        winner: this.checkScore(),
        pending: true
      })

      this.checkByOtherPlayer = !this.checkByOtherPlayer
    }
  }
}
</script>
